import type { Cart } from '@features/api/shopify/types';
import { formatMoney } from '@features/shared/utils/formatMoney';
import { formatPriceAmount } from '@features/webshop/cart/utils/formatPriceAmount';
import { getTotalCartDiscount } from '@features/webshop/cart/utils/getTotalCartDiscount';

export function formatCartTotals(cart?: Cart) {
	if (!cart)
		return {
			totalCartPriceAmount: 0,
			totalCartPrice: '0.00',
			totalCartDiscount: 0,
			priceBeforeDiscountAmount: 0,
			priceBeforeDiscount: '0.00',
			discountCodes: [],
			discountAllocations: [],
			isCartDiscounted: false,
			hasAppliedDiscount: false,
		};

	const totalCartPriceAmount = formatPriceAmount(cart.cost.totalAmount.amount);
	const totalCartPrice = formatMoney(cart.cost.totalAmount);
	const totalCartDiscount = getTotalCartDiscount(cart.discountAllocations, cart.lines);
	const priceBeforeDiscountAmount = totalCartPriceAmount + totalCartDiscount;
	const priceBeforeDiscount = formatMoney({ amount: priceBeforeDiscountAmount });
	const discountCodes = cart.discountCodes.filter((disciuntCode) => disciuntCode.applicable);
	const discountAllocations = cart.discountAllocations;

	const isCartDiscounted = totalCartPriceAmount < priceBeforeDiscountAmount;
	const hasAppliedDiscount = discountCodes.length > 0 || discountAllocations.length > 0;

	return {
		totalCartPriceAmount,
		totalCartPrice,
		totalCartDiscount,
		priceBeforeDiscountAmount,
		priceBeforeDiscount,
		isCartDiscounted,
		hasAppliedDiscount,
		discountAllocations,
		discountCodes,
	};
}
