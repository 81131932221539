'use client';

import { BtnColorEnum, BtnSizeEnum, BtnVariantEnum } from '@features/api/client';
import { cn } from '@features/shared/utils/utils';
import { Button } from '@features/webshop/shared/ui/Button';
import { useMediaPlayer } from '@vidstack/react';
import '@vidstack/react/player/styles/base.css';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const IconMRedTrianglePointedDown = dynamic(
	() => import('@features/webshop/landing-pages/ui/parts/FluentEmojiIcon/IconMRedTrianglePointedDown'),
	{ ssr: false }
);

export type VideoBackgroundProps = {
	buttonPosition?: 'buttonInTheMiddle' | 'buttonBottomLeft';
	buttonColor?: BtnColorEnum;
};

function VideoBackground({
	buttonPosition = 'buttonInTheMiddle',
	buttonColor = BtnColorEnum.Dark,
}: VideoBackgroundProps) {
	const [isVisible, setIsVisible] = useState(true);
	const player = useMediaPlayer();

	return (
		<div
			className={cn('absolute inset-0 z-30 cursor-pointer', isVisible ? 'block' : 'hidden')}
			onClick={() => {
				player?.play();
				setIsVisible(false);
			}}>
			{buttonPosition === 'buttonInTheMiddle' && (
				<span className="absolute inset-0 z-20 flex cursor-pointer items-center justify-center backdrop-blur-sm">
					<Button
						className="shadow-[rgba(255,255,255,0.1)] [&_svg]:!h-6 [&_svg]:!w-6 [&_svg]:!-rotate-90"
						size={BtnSizeEnum.Small}
						color={buttonColor}
						variant={BtnVariantEnum.Primary}>
						<span className="whitespace-nowrap">Pogledaj</span> <IconMRedTrianglePointedDown />
					</Button>
				</span>
			)}

			{buttonPosition === 'buttonBottomLeft' && (
				<Button
					className="absolute bottom-2 right-2 z-10 gap-1 py-1 pl-3 pr-1 text-xs [&_svg]:!h-6 [&_svg]:!w-6 [&_svg]:!-rotate-90"
					size={BtnSizeEnum.Small}
					color={buttonColor}
					variant={BtnVariantEnum.Primary}>
					<span className="whitespace-nowrap">Pogledaj</span> <IconMRedTrianglePointedDown />
				</Button>
			)}
		</div>
	);
}

export default VideoBackground;
