'use client';
import * as R from 'remeda';

import { ConfigProvider } from 'antd';
import baseTheme from './baseTheme';
import frontofficeTheme from './frontofficeTheme';

const withFrontofficeTheme = (node: React.ReactNode) => (
	<ConfigProvider theme={R.mergeDeep(baseTheme, frontofficeTheme)}>{node}</ConfigProvider>
);

export default withFrontofficeTheme;
