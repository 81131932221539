'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect, useRef, type ReactNode } from 'react';

type ScrollIntoViewIfDiscountProps = {
	children: ReactNode;
};

function ScrollIntoViewIfDiscount({ children }: ScrollIntoViewIfDiscountProps) {
	const scrollIntoViewRef = useRef<HTMLDivElement>(null);
	const renderRef = useRef(false);

	const searchParams = useSearchParams();
	const discountCode = searchParams.get('discount');

	useEffect(() => {
		if (!discountCode || renderRef.current || !scrollIntoViewRef.current) return;

		renderRef.current = true;
		scrollIntoViewRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}, [discountCode]);

	return <div ref={scrollIntoViewRef}>{children}</div>;
}

export default ScrollIntoViewIfDiscount;
