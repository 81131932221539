import { IconDownload, IconLoader2, IconX } from '@tabler/icons-react';
import { cn } from '@features/shared/utils/utils';
import type { MaturaDownloaderGenerateZipResponseUrl } from '@features/api/client';

function MaturaDownloadItems({ items }: { items: Array<MaturaDownloaderGenerateZipResponseUrl> }) {
	if (items.length <= 0) return null;

	return (
		<div className="flex w-full flex-col gap-4">
			<div className="text.lg font-bold">Datoteke se nisu automatski preuzele?</div>
			<ul className="flex w-full flex-col gap-2">
				{items?.map((item, index) => (
					<li
						key={`${item.title}-${index}`}
						className={cn(
							'flex w-full gap-3 border-b border-dashed border-gray-200 py-2'
							// item.status === 'not-started' && 'cursor-not-allowed opacity-75'
						)}>
						<a
							href={item.url}
							download={item.title}
							className={cn(
								'flex w-full justify-between gap-3'
								// item.status === 'not-started' && 'pointer-events-none'
							)}>
							<span>{item.title}</span>
							<IconDownload className="text-primary-blue" />
							{/* {item.status === 'error' && <IconX className="text-destructive" />}
							{item.status === 'success' && <IconDownload className="text-primary-blue" />}
							{item.status === 'pending' && (
								<IconLoader2 className="animate-spin text-gray-600" />
							)} */}
						</a>
					</li>
				))}
			</ul>
			<span className="text-sm text-gray-300">
				Želiš <code>.rar</code> datoteke? Pretvori ih besplatno pomoću{' '}
				<a
					href="https://cloudconvert.com/zip-to-rar"
					target="_blank"
					rel="noopener noreferrer"
					className="text-primary-blue underline">
					ovog alata.
				</a>
			</span>
		</div>
	);
}

export default MaturaDownloadItems;
