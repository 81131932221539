'use client';

import { type Cart } from '../../../../../api/shopify/types';
import useUpdateCartOnChange from '@features/webshop/cart/hooks/useUpdateCartOnChange';
import ProductCardItem from './ProductCardItem';
import type { MergedProduct } from '@features/webshop/cart/utils/getMergedProducts';
import { apiClient } from '@features/api/api.client';

type ProductCardItemsProps = {
	mergedProducts: Array<MergedProduct>;
	initialCart?: Cart;
};

function ProductCardItems({ mergedProducts, initialCart }: ProductCardItemsProps) {
	const { data: cart } = apiClient.cart.useGetCart({ initialData: initialCart });

	useUpdateCartOnChange({ cart });

	return (
		<div className="grid auto-rows-fr grid-cols-1">
			{mergedProducts.map(({ cmsProduct, shopifyProduct }) => {
				const lineItem = cart?.lines.find(
					(line) => line.merchandise.product.id === shopifyProduct.id
				);

				const currentVariant = shopifyProduct.variants[0];
				if (!cmsProduct || !shopifyProduct || !currentVariant) return null;

				return (
					<ProductCardItem
						title={cmsProduct.short_title || cmsProduct.title}
						lineItem={lineItem}
						currentVariant={currentVariant}
						key={lineItem?.id || currentVariant.id}
					/>
				);
			})}
		</div>
	);
}

export default ProductCardItems;
