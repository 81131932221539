'use client';

import { type ReactNode } from 'react';
import { cn } from '@features/shared/utils/utils';
import { IconCheck } from '@tabler/icons-react';
import { useCartStore } from '@features/webshop/cart/CartStore';

type SubscriptionAddToCartButtonProps = {
	children?: ReactNode;
	shopifyProductVariantId: string;
};

function SubscriptionAddToCartButton({
	shopifyProductVariantId,
	children,
}: SubscriptionAddToCartButtonProps) {
	const selectedVariantIds = useCartStore((state) => state.selectedVariantIds);
	const setSelectedVariantIds = useCartStore((state) => state.setSelectedVariantIds);
	const isSelected = selectedVariantIds.includes(shopifyProductVariantId);

	if (!shopifyProductVariantId) return null;

	return (
		<span
			onClick={() => {
				setSelectedVariantIds([shopifyProductVariantId]);
			}}>
			<button
				suppressHydrationWarning
				className={cn(
					'relative h-14 w-14 rounded-full border-[3px] border-solid border-white/50 bg-white/20 text-white shadow-[0_0_10px_0_rgba(0,0,0,0.10)] backdrop-blur-[2px] md:h-20 md:w-20',
					isSelected && ' border-white bg-white text-primary-blue '
				)}>
				{children}
				{isSelected && (
					<IconCheck className="absolute -right-1 -top-1 h-6 w-6 rounded-full bg-white p-1 text-primary-blue md:h-8 md:w-8" />
				)}
			</button>
		</span>
	);
}

export default SubscriptionAddToCartButton;
