'use client';

import type { Cart } from '@features/api/shopify/types';
import { cn } from '@features/shared/utils/utils';
import { BtnSizeEnum, BtnColorEnum, BtnVariantEnum } from '@features/api/client';
import { Button } from '../../../../shared/ui/Button';
import { IconChevronRight } from '@tabler/icons-react';
import DiscountTag from '../../parts/DiscountTag/DiscountTag';
import useGetCartWithTotals from '@features/webshop/cart/hooks/useCartTotals';
import { apiClient } from '@features/api/api.client';
import DiscountCodeRibbon from '@features/webshop/landing-pages/ui/parts/DiscountCodeRibbon/DiscountCodeRibbon';
import { useRouter } from 'next/navigation';

function ProductCardTotals({ initialCart }: { initialCart?: Cart }) {
	const router = useRouter();
	const cartQuery = apiClient.cart.useGetCart({ initialData: initialCart });
	const { data: cart } = cartQuery;
	const {
		isCartUpdating,
		isCartDiscounted,
		priceBeforeDiscount,
		totalCartPrice,
		hasAppliedDiscount,
		discountCodes,
		discountAllocations,
	} = useGetCartWithTotals({ cartQuery });

	return (
		<div className="flex w-full flex-col gap-3 xs:gap-4">
			<div className="flex flex-col">
				<div
					suppressHydrationWarning
					className={cn(
						'flex items-center justify-end gap-2 sm:gap-4',
						isCartUpdating && 'opacity-70'
					)}>
					{isCartDiscounted && (
						<span className="text-[22px] font-light line-through xs:text-[22px] sm:text-3xl">
							{priceBeforeDiscount}
						</span>
					)}
					<span className="text-[2rem] font-extrablack leading-none xs:text-3xl sm:text-5xl">
						{totalCartPrice}
					</span>
				</div>
				{discountCodes.map((discountCode) => (
					<DiscountCodeRibbon
						key={discountCode.code}
						discountCode={discountCode}
						className="self-end border-black/20 text-black [&>svg]:text-primary-blue"
					/>
				))}
			</div>
			{hasAppliedDiscount && (
				<div className="flex flex-col gap-2">
					{discountAllocations?.map((discountAllocation) => {
						if (discountAllocation.__typename === 'CartCodeDiscountAllocation') return;

						return (
							<DiscountTag
								title={discountAllocation.code || discountAllocation.title}
								key={discountAllocation.code || discountAllocation.title}
							/>
						);
					})}
				</div>
			)}

			<Button
				size={BtnSizeEnum.Large}
				color={BtnColorEnum.Red}
				variant={BtnVariantEnum.Primary}
				disabled={!cart}
				className="w-full"
				onClick={() => {
					if (!cart) return;

					router.push(cart.checkoutUrl);
				}}>
				<span>Kupi pripreme</span>
				<IconChevronRight />
			</Button>
		</div>
	);
}

export default ProductCardTotals;
