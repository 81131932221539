'use client';

import type { Cart } from '@features/api/shopify/types';
import { IconCheck } from '@tabler/icons-react';
import { type ButtonHTMLAttributes, type ReactNode } from 'react';
import { useCartStore } from '@features/webshop/cart/CartStore';

type ToggleCartItemButtonProps = {
	initialCart?: Cart;
	children?: ReactNode;
	shopifyProductVariantId: string;
};

function RemoveButton({
	isLoading,
	...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }) {
	if (isLoading) return <AddButton />;

	return (
		<button
			{...rest}
			suppressHydrationWarning
			className="flex h-6 min-h-6 w-6 min-w-6 items-center justify-center rounded-full border-[3px] border-solid border-gray-800 bg-gray-800 text-white shadow-[0_0_10px_0_rgba(0,0,0,0.10)] xs:h-7 xs:min-h-7 xs:w-7 xs:min-w-7">
			<IconCheck strokeWidth={3} className="text-bray-800 h-4 w-4 xs:h-5 xs:w-5" />
		</button>
	);
}

function AddButton({
	isLoading,
	...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }) {
	if (isLoading) return <RemoveButton />;

	return (
		<button
			{...rest}
			suppressHydrationWarning
			className="h-6 min-h-6 w-6 min-w-6 rounded-full border-[3px] border-solid border-gray-800 shadow-[0_0_10px_0_rgba(0,0,0,0.10)] xs:h-7 xs:min-h-7 xs:w-7 xs:min-w-7"></button>
	);
}

function ToggleCartItemButton({ shopifyProductVariantId, children }: ToggleCartItemButtonProps) {
	const selectedVariantIds = useCartStore((state) => state.selectedVariantIds);
	const setSelectedVariantIds = useCartStore((state) => state.setSelectedVariantIds);
	const isSelected = selectedVariantIds.includes(shopifyProductVariantId);

	if (!shopifyProductVariantId) return null;

	if (!isSelected) {
		return (
			<span
				className="mb-2.5 flex cursor-pointer gap-4 border-b-[1.5px] border-dashed border-gray-800/60 pb-2.5 xs:mb-4 xs:border-b-[1.5px] xs:pb-4 sm:gap-7"
				onClick={() => {
					setSelectedVariantIds([...selectedVariantIds, shopifyProductVariantId]);
				}}>
				<AddButton />
				{children}
			</span>
		);
	}
	return (
		<span
			className="mb-2.5 flex cursor-pointer gap-4 border-b-[1.5px] border-dashed border-gray-800/60 pb-2.5 xs:mb-4 xs:border-b-[1.5px] xs:pb-4 sm:gap-7"
			onClick={() => {
				setSelectedVariantIds(selectedVariantIds.filter((id) => id !== shopifyProductVariantId));
			}}>
			<RemoveButton />
			{children}
		</span>
	);
}

export default ToggleCartItemButton;
