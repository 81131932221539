import { useIsMutating, type UseQueryResult } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { isDeepEqual } from 'remeda';
import { queryKeys } from 'settings/queryKeys';
import { useTimeout } from 'usehooks-ts';
import type { Cart } from '@features/api/shopify/types';
import { formatCartTotals } from '@features/webshop/cart/utils/formatCartTotals';
import { useCartStore } from '@features/webshop/cart/CartStore';

function useCartTotals({ cartQuery }: { cartQuery: UseQueryResult<Cart, Error> }) {
	const numberOfCartMutations = useIsMutating({ mutationKey: queryKeys.cart.update() });
	const isMutating = numberOfCartMutations > 0;

	const [isLoading, setIsLoading] = useState(false);
	const [delay, setDelay] = useState<number | null>(null);
	const selectedVariantIds = useCartStore((state) => state.selectedVariantIds);

	const cart = cartQuery.data;
	const cartTotals = formatCartTotals(cart);

	useTimeout(() => {
		setDelay(null);
		setIsLoading(false);
	}, delay);

	useEffect(() => {
		const variantIdsInCart = cart?.lines.map((line) => line.merchandise.id) || [];
		const areNewVariantsAddedToCart = !isDeepEqual(variantIdsInCart, selectedVariantIds);
		if (!areNewVariantsAddedToCart) return;

		setIsLoading(true);
		setDelay(200);
	}, [selectedVariantIds]);

	const isCartUpdating = isLoading || cartQuery.isRefetching || isMutating;

	return { isCartUpdating, ...cartTotals };
}

export default useCartTotals;
