'use client';
import { type SellingPlanGroup } from '@features/api/shopify/types';
import { cn } from '@features/shared/utils/utils';
import { formatAdjustmentValue } from '@features/shared/utils/formatMoney';
import type { ReactNode } from 'react';
import { useCartStore } from '@features/webshop/cart/CartStore';
import { IconCheck } from '@tabler/icons-react';

type SubscriptionPlanToggleButtonProps = {
	sellingPlanGroup: SellingPlanGroup;
	children: ReactNode;
};

function SubscriptionPlanToggleButton({ sellingPlanGroup, children }: SubscriptionPlanToggleButtonProps) {
	const selectedSellingPlan = useCartStore((state) => state.selectedSellingPlan);
	const setSelectedSellingPlan = useCartStore((state) => state.setSelectedSellingPlan);

	const sellingPlan = sellingPlanGroup.sellingPlans[0];
	const adjustment = sellingPlan?.priceAdjustments[0];
	const isFixedValue = adjustment?.adjustmentValue?.__typename === 'SellingPlanFixedPriceAdjustment';
	const adjustmentValue = isFixedValue
		? '40%'
		: formatAdjustmentValue(sellingPlan?.priceAdjustments[0]?.adjustmentValue);

	const isSelected = selectedSellingPlan?.id === sellingPlan?.id;

	return (
		<div
			className={cn(
				'relative flex flex-1 cursor-pointer items-center justify-center gap-2 rounded-2xl border border-solid border-white px-2.5 py-5 max-sm:flex-1 max-sm:first:rounded-r-none max-sm:last:rounded-l-none xs:border-2 xs:px-4 sm:border-[3px] sm:px-5',
				isSelected && 'bg-white text-primary-blue'
			)}
			onClick={() => setSelectedSellingPlan(sellingPlan)}>
			{children}
			{isSelected && (
				<IconCheck className="hidden h-5 w-5 rounded-full bg-primary-blue/90 p-1.5 text-white sm:block md:h-7 md:w-7" />
			)}
			{adjustmentValue && (
				<div className="absolute left-1/2 top-full flex -translate-y-1/2 items-start justify-start">
					<div
						className={cn(
							'flex -translate-x-1/2 items-center justify-center gap-0.5 rounded-2xl bg-gray-800 px-4 py-1 text-xs font-bold text-white',
							isSelected && 'bg-primary-yellow text-black'
						)}>
						{adjustmentValue} <span className="hidden text-xs uppercase md:inline">off</span>
					</div>
				</div>
			)}
		</div>
	);
}

export default SubscriptionPlanToggleButton;
