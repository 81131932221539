import { IconTag } from '@tabler/icons-react';

type DiscountTagProps = {
	title: string | undefined;
};

function DiscountTag({ title }: DiscountTagProps) {
	if (!title) return null;

	return (
		<div className="inline-flex items-center justify-center gap-1 rounded-full border-[0.5px] border-solid border-gray-800 bg-transparent px-2 py-0.5 text-gray-800 xs:gap-1.5 xs:border xs:px-3 xs:py-1">
			<IconTag className="h-2.5 w-2.5 xs:h-3 xs:w-3 sm:h-3.5 sm:w-3.5" />
			<span className="whitespace-nowrap text-center text-[0.625rem] font-bold xs:text-xs">
				{title}
			</span>
		</div>
	);
}

export default DiscountTag;
