import type { Money, ShopifySellingPlan } from '@features/api/shopify/types';

function formatSellingPlanPrices({
	sellingPlan,
	cartTotalAmount,
}: {
	sellingPlan: ShopifySellingPlan | undefined;
	cartTotalAmount: Money | undefined;
}) {
	if (!sellingPlan || !cartTotalAmount)
		return {
			monthlyAmount: (0).toFixed(2),
			yearlyAmount: (0).toFixed(2),
			weeklyAmount: (0).toFixed(2),
			currencyCode: 'EUR',
		};

	if (sellingPlan?.name.includes('year')) {
		return {
			monthlyAmount: (parseFloat(cartTotalAmount.amount) / 12).toFixed(2),
			yearlyAmount: cartTotalAmount.amount,
			weeklyAmount: (parseFloat(cartTotalAmount.amount) / 52).toFixed(2),
			currencyCode: cartTotalAmount?.currencyCode,
		};
	}

	if (sellingPlan?.name.includes('week')) {
		return {
			monthlyAmount: (parseFloat(cartTotalAmount.amount) * 4).toFixed(2),
			yearlyAmount: (parseFloat(cartTotalAmount.amount) * 52).toFixed(2),
			weeklyAmount: cartTotalAmount.amount,
			currencyCode: cartTotalAmount?.currencyCode,
		};
	}

	return {
		monthlyAmount: cartTotalAmount.amount,
		yearlyAmount: (parseFloat(cartTotalAmount.amount) * 12).toFixed(2),
		weeklyAmount: (parseFloat(cartTotalAmount.amount) / 4).toFixed(2),
		currencyCode: cartTotalAmount?.currencyCode,
	};
}

export default formatSellingPlanPrices;
