'use client';

import { formatMoney } from '@features/shared/utils/formatMoney';
import { type CartItem, type ProductVariant } from '../../../../../api/shopify/types';
import ToggleCartItemButton from './ToggleCartItemButton';
import DiscountTag from '../../parts/DiscountTag/DiscountTag';

type ProductCardItemProps = {
	title: string;
	lineItem?: CartItem;
	currentVariant: ProductVariant;
};

function ProductCardItem({ lineItem, currentVariant, title }: ProductCardItemProps) {
	const totalDiscount = lineItem?.discountAllocations.reduce(
		(acc, curr) => acc + parseFloat(curr.discountedAmount.amount),
		0
	);

	const compareAtPrice = lineItem?.cost?.compareAtAmountPerQuantity
		? formatMoney(lineItem?.cost?.compareAtAmountPerQuantity)
		: currentVariant.compareAtPrice
			? formatMoney(currentVariant?.compareAtPrice)
			: null;

	const price = formatMoney({
		amount: lineItem?.cost?.totalAmount?.amount || currentVariant.price.amount,
		currencyCode: currentVariant.price.currencyCode,
	});

	const totalAmount = parseFloat(lineItem?.cost?.totalAmount?.amount || currentVariant.price.amount);
	const compareAtAmount = parseFloat(
		lineItem?.cost?.compareAtAmountPerQuantity?.amount || currentVariant.compareAtPrice?.amount || '0'
	);

	const shouldShowCompareAtPrice = !!compareAtPrice && compareAtAmount > totalAmount;

	return (
		<ToggleCartItemButton shopifyProductVariantId={currentVariant.id}>
			<div className="flex min-h-[40px] w-full flex-col gap-1 xs:min-h-[44px] sm:min-h-[54px]">
				<div className="flex w-full items-start justify-between gap-3">
					<div className="flex flex-col gap-0.5">
						<span className="flex items-center whitespace-nowrap text-base font-black !leading-none xs:text-xl sm:text-2xl">
							{title}
						</span>
						{lineItem && (
							<div className="flex gap-2">
								{lineItem?.discountAllocations.map(
									(discountAllocation) =>
										discountAllocation.title &&
										!!totalDiscount && (
											<DiscountTag
												key={discountAllocation.title}
												title={`${discountAllocation.title} (-${formatMoney({ amount: totalDiscount })})`}
											/>
										)
								)}
							</div>
						)}
					</div>
					<span className="flex flex-col-reverse items-end gap-1 max-xs:self-end xs:flex-row xs:items-center sm:gap-3 ">
						{shouldShowCompareAtPrice && (
							<span className="xs:text.lg text-base font-light !leading-none line-through sm:text-xl">
								{compareAtPrice}
							</span>
						)}

						<span className="text-xl font-black !leading-none xs:text-2xl sm:text-3xl">
							{price}
						</span>
					</span>
				</div>
			</div>
		</ToggleCartItemButton>
	);
}

export default ProductCardItem;
