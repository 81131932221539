import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import Cookies from 'js-cookie';

function usePreservedDiscountCode() {
	const searchParams = useSearchParams();
	const searchParamsDiscountCode = searchParams.get('discount');
	const searchParamsAffiliateId = searchParams.get('dt_id');
	const searchParamsAffiliateRef = searchParams.get('sca_ref');

	const [localStorageDiscountCode, setLocalStorageDiscountCode] = useLocalStorage(
		'discountCode',
		searchParamsDiscountCode
	);

	useEffect(() => {
		if (!searchParamsAffiliateRef) return;

		Cookies.set('sca_ref', searchParamsAffiliateRef, {
			domain: '.gradivo.hr',
			path: '/',
			expires: 30,
		});
	}, [searchParamsAffiliateRef]);

	useEffect(() => {
		if (!searchParamsAffiliateId) return;

		Cookies.set('dt_id', searchParamsAffiliateId, {
			domain: '.gradivo.hr',
			path: '/',
			expires: 30,
		});
	}, [searchParamsAffiliateId]);

	useEffect(() => {
		if (!searchParamsDiscountCode) return;

		setLocalStorageDiscountCode(searchParamsDiscountCode);

		Cookies.set('discount_code', searchParamsDiscountCode, {
			domain: '.gradivo.hr',
			path: '/',
			expires: 30,
		});
	}, [searchParamsDiscountCode]);

	return {
		discountCode: searchParamsDiscountCode || localStorageDiscountCode,
	};
}

export default usePreservedDiscountCode;
