import type { DiscountAllocation, CartItem } from '@features/api/shopify/types';

export function getTotalCartDiscount(
	discountAllocations: Array<DiscountAllocation>,
	lineItems: Array<CartItem>
) {
	const totalDiscountallocationsAmount = discountAllocations.reduce((sum, discountAllocation) => {
		const amountValue = parseFloat(discountAllocation.discountedAmount.amount);
		const amount = isNaN(amountValue) ? 0 : amountValue;

		return sum + amount;
	}, 0);

	const totalLineItemsAmount =
		lineItems
			.flatMap((line) => line.discountAllocations)
			.reduce((sum, discountAllocation) => {
				const amountValue = parseFloat(discountAllocation.discountedAmount.amount);
				const amount = isNaN(amountValue) ? 0 : amountValue;

				return sum + amount;
			}, 0) || 0;

	return totalDiscountallocationsAmount + totalLineItemsAmount;
}
