'use client';

import type { ShopifySellingPlan } from '@features/api/shopify/types';
import createZustandContext from '@features/shared/providers/createZustandContext';
import { createStore } from 'zustand';

type InitialState = {
	selectedSellingPlan: ShopifySellingPlan | undefined;
	selectedVariantIds: Array<string>;
};

type CartStoreState = InitialState & {
	setSelectedSellingPlan: (value: ShopifySellingPlan | undefined) => void;
	setSelectedVariantIds: (value: Array<string>) => void;
};

const CartStore = createZustandContext<InitialState, CartStoreState>((initialState) => {
	return createStore<CartStoreState>()((set, get) => ({
		selectedSellingPlan: initialState.selectedSellingPlan,
		selectedVariantIds: initialState.selectedVariantIds,
		setSelectedSellingPlan: (value) =>
			set((state) => {
				return {
					selectedSellingPlan: value,
				};
			}),
		setSelectedVariantIds: (value) =>
			set((state) => {
				return {
					selectedVariantIds: value,
				};
			}),
	}));
});

export const useCartStore = CartStore.useStore;
export const CartStoreProvider = CartStore.Provider;
